<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :inlineLabel="true"
      :tabItems.sync="tabItems"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :contentHeight="contentHeight"
          @deleteAssess="deleteAssess"
          @setRegInfo="setRegInfo"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'vendor-assess-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmVendorEvaluationId: '',
        vendorCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      tab: 'vendorAssessInfo',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    tabItems() {
      let items = [
        // 평가정보
        { name: 'vendorAssessInfo', icon: 'edit', label: 'LBL0003013', component: () => import(`${'./vendorAssessInfo.vue'}`) },
      ];
      if (this.popupParam.mdmVendorEvaluationId) {
        // 평가이력
        items.push({ name: 'vendorAssessHistory', icon: 'history', label: 'LBL0003014', component: () => import(`${'./vendorAssessHistory.vue'}`) })
      }
      return items;
    }
  },
  methods: {
    init() {
    },
    deleteAssess() {
      this.$emit('closePopup');
    },
    setRegInfo(data) {
      this.$emit('setRegInfo', data)
    }
  }
};
</script>
